import React, {useState, useContext, useEffect} from 'react';
import { memberlist, teamdetails, removemember, addmember} from '../data/data';
import CsvDownload from 'react-json-to-csv';
import { Store } from '../stores/Store';
import SearchBox from '../components/SearchBox';
const Emaillist = () => {
    let context = useContext(Store)
    let [memberdata, setMemberData] = useState(null);
    let [teamDetails, setTeamDetailsData] = useState(null);
    let [seatsleft, setSeatsLeft] = useState(null);
    const getTeamDetails = async () => {
        await teamdetails(context).then(responce=>{
            setTeamDetailsData((responce.status)?[]:responce.data);
        });
    }
    const getMemberlist = async () => {
        await memberlist(context).then(responce=>{
            setMemberData((responce.status)?[]:responce.data);
            setSeatsLeft(responce.data.length)
        });
    }
    const removeMember = async (email) => {
        await removemember(context, teamDetails.id, email).then(responce=>{
            if(responce.data.success === true){
                getMemberlist();
            }
        });
    }
    
    useEffect(()=>{
        if(!memberdata){
            getMemberlist();
        }
        if(!teamDetails){
            getTeamDetails();
        }
    }, [memberdata, teamDetails]);
    return (memberdata && teamDetails)?(
        <div className="dashboard">
            <div className="center" style={{marginTop:'10px'}}>
                <h2 style={{width:'100%', textAlign:'center'}}>Users for {teamDetails.name}</h2>
                <p style={{width:'50%', textAlign:'center'}}>Ask users to create a free account on either the websire or mobile application, they should not subscribe! If users already have a subscription they should cancel their subscription as adding them to your team will not automatically cancel their subscription.</p>
            </div>
            <div className="center">
                {<p style={{width:'50%', textAlign:'center', fontSize:21, color:'#1ab154'}}>{teamDetails.seats - seatsleft} seats left</p>}
            </div>
            <div className="center emailbutton">
                {
                    (teamDetails.seats  > seatsleft)
                    ?
                        <SearchBox {...{team_id:teamDetails.id, callback:getMemberlist}} />
                    :
                        <p style={{width:'50%', textAlign:'center'}}>Contact team@dandelionmeditation to add more seats.</p>
                }
            </div>
            <div className="center">
                <div className="box">
                    {(memberdata.length > 0)?<table>
                        <thead>
                            <td>#</td>
                            <td>
                                Name
                            </td>
                            <td>
                                Email
                            </td>
                            <td>
                                Remove
                            </td>
                        </thead>
                        {memberdata.map((item, i)=><tr>
                            <td>{i+1}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{(item.email !== context.user.email)&&<span onClick={()=>removeMember(item.email)}>Remove</span>}</td>
                        </tr>)}
                    </table>:<p>No Members Yet</p>}
                </div>
            </div>
            <div className="center emailbutton" style={{margin:'10px 5%'}}>
                <CsvDownload data={memberdata} />
            </div>
        </div>
    ):<p>Loading</p>
}
export default Emaillist;