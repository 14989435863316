import React, {useContext, useEffect} from 'react';
import { Store } from './stores/Store';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Login from './pages/Login';
import {useToasts } from 'react-toast-notifications'
import Emaillist from './pages/Emaillist';
import TopBar from './components/TopBar';
import BottomBar from './components/BottomBar';
const Routes = () => {
    const context = useContext(Store);
    const { addToast } = useToasts()
    useEffect(()=>{
        if(!context.notify){
            context.update(
                {
                    notify:(notifyobj)=>addToast(notifyobj.message, { appearance: notifyobj.type, autoDismiss: true })
                }
            );
        }
    });
    return (
        <BrowserRouter>
            {(!context.token || !context.user)
            ?
            <Switch>
                <Route path='/' exact component={Login} />
            </Switch>
            :
            <Switch>
                <div>
                    <TopBar/>
                    <Route path='/' exact component={Emaillist} />
                    <BottomBar />
                </div>
                
            </Switch>}
        </BrowserRouter>
    )
}
export default Routes