import React, {useState, useContext, useEffect} from 'react';
import { login, user} from '../data/auth';
import { Store } from '../stores/Store';

const Login = () => {
    const context = useContext(Store);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loggingin, setLoggingIn] = useState(null);
    const submit = () =>{
        setLoggingIn(true);
        if(!loggingin){
            userlogin(email, password, context);
        } 
    }
    useEffect(()=>{
        if(context.token){
            let thisuser = user(context).then((responce)=>{
                if(thisuser.data && thisuser.data.success === false){
                    context.notify({
                        type:'error',
                        message:'You are not a team admin please contact team@dandelionmeditation to setup a team!'
                    });
                    setTimeout(()=>{
                        context.reset();
                        setLoggingIn(null);
                    }, 2000);
                }else{
                    context.update({user:thisuser});
                }
            });
        }
    }, [context]);
    const userlogin =  async () =>{
        login(email, password, context).then(async (response)=>{ 
            console.log(response);
            
            if(response.status === 401)
            {
                context.notify({
                    type:'error',
                    message:'There was an issue authenticating your username or password!'
                });
            }
            if(response.status === 500)
            {
                context.notify({
                    type:'error',
                    message:'We are having issues with our servers - Please try later'
                });
            }
            if(response.status === 200)
            {
                context.notify({
                    type:'success',
                    message:'Logging in',
                });
                
            }
        });
    }
    return (
        <div className='center'>
            <div className='box col5 space'>
                <div className='center'>
                    <img className='col5' alt='logo' src='/images/logo.png' />
                </div>
                <div className='center'>
                    <h3>Team Admin Area</h3>
                </div>
                <input placeholder='Email' className='form-control' onChange={(e)=>{setEmail(e.target.value)}} value={email} /> 
                <input placeholder='Password' type='password' className='form-control' onChange={(e)=>{setPassword(e.target.value)}} value={password} />
                <button className='btn success' onClick={submit}>Submit</button>
            </div>
        </div>
    );
}
export default Login;