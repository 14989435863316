import React, {useState, useContext} from 'react';
import { Store } from '../stores/Store';
import {addmember} from '../data/data';
const SearchBox = (props) =>{
    let [email, setEmail] = useState('');
    let context = useContext(Store);
    const addMember = async () => {
        await addmember(context, props.team_id, email).then(responce=>{
            setEmail('');
            if(responce.data.success === true){
                context.notify({
                    type:'success',
                    message:'User added, please advise it takes up to 24 hours to take effect',
                });
                props.callback();
            }else{
                props.callback();
                context.notify({
                    type:'error',
                    message:'There has been an issue adding this user',
                });
                if(responce.data.message === 'Seats Full'){
                    context.notify({
                        type:'error',
                        message:'Your seats are full',
                    });
                }
            }
        });
    }
    return (
        <div className='center' style={{width:'50%'}}>
            <input style={{width:'50%'}} value={email} onChange={(e)=>setEmail(e.target.value)} className={'form-control'} placeholder={'Enter Email of User Member'} type='text' /><br/>
            <button onClick={addMember}>Add User</button>
        </div>
    )
}
export default SearchBox