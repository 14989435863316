import React, {useState, useEffect} from 'react'
import {Provider} from "./stores/Store";
import Routes from './Routes';
import './sass/index.scss';
import { ToastProvider } from 'react-toast-notifications'
const App = () => {
  const [state, setState] = useState(null);
  useEffect(()=>{
    if(state === null){
      setupContext();
    }
  }, []);
  useEffect(()=>{
    if(state !== null){
      sessionStorage.setItem('state', JSON.stringify(state));
    }else{
      setupContext();
    }
  }, [state]);
  const setupContext = (newobj = {}) => {
    let session = JSON.parse(sessionStorage.getItem('state'));
    let Obj = (session&& session.token)?session:{}
    Obj.update = update;
    Obj.reset = reset;
    setState(prevState => {
      return {...prevState, ...Obj};
    });
  }
  const update = (newObj) => {
    setState(prevState => {
      return {...prevState, ...newObj};
    });
  }
  const reset = () => {
    sessionStorage.setItem('state', JSON.stringify({}));
    sessionStorage.clear('state');
    setState(prevState => {
      return null;
    });
  }
  return state?(
    <ToastProvider>
      <Provider value={state}>
        <Routes />
      </Provider>
    </ToastProvider>
  ):<p>Loading</p>;
}

export default App;
