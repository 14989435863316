import axios from 'axios';
const adminapi = 'https://organisations.dandelionmeditation.co.uk';

export const memberlist = async (context) => {
    try {
        const response = await axios.get(adminapi + '/team/1/members', {
            headers: {
                Authorization: 'Bearer ' + context.token
            }
        });
        return response.data;
    } catch (error) {
        return error.response;
    }
}
export const teamdetails = async (context) => {
    try {
        const response = await axios.get(adminapi + '/team', {
            headers: {
                Authorization: 'Bearer ' + context.token
            }
        });
        return response.data;
    } catch (error) {
        return error.response;
    }
}
export const removemember = async (context, team_id, email) => {
    try {
        const response = await axios.patch(adminapi + '/team/'+team_id+'/members/remove', {
            email:email
        }, {
            headers: {
                Authorization: 'Bearer ' + context.token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const addmember = async (context, team_id, email) => {
    try {
        const response = await axios.patch(adminapi + '/team/'+team_id+'/members/add', {
            email:email
        }, {
            headers: {
                Authorization: 'Bearer ' + context.token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}